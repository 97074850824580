export const ROUTE_HOME = 'home';
export const ROUTE_LOGOUT_SUCCESS = '#logout=success';

// Utility function for relative paths
export function routePrefix(route: string) {
  return '/' + route;
}

// Utility function for full URL paths
export function fullRoutePrefix(route: string) {
  // check if route already starts with /
  if (route.startsWith('/')) {
    return window.location.origin + route;
  }

  return window.location.origin + '/' + route;
}

export function homeRoutePrefix(route: string) {
  return '/' + ROUTE_HOME + routePrefix(route);
}

// DATE PICKER INPUT FIELD REGEX UTILS
export const DATE_SEPARATOR = '.';
export const DAY_PART_INDEX = 0;
export const MONTH_PART_INDEX = 1;
export const YEAR_PART_INDEX = 2;
export const CLEAN_DATE_REGEX = /[^\d.]/g;
export const ZERO_PADDED_DATE_REGEX = /\.(\d)\./;
