import { Component, input, model } from '@angular/core';
import { NgClass } from '@angular/common';

@Component({
  selector: 'lib-ikp-info-card-chevron-button',
  standalone: true,
  imports: [NgClass],
  templateUrl: './info-card-chevron-button.component.html',
  styles: ['.btn { height: 2.5rem; width: 2.5rem }', '.fas { color: white }'],
})
export class InfoCardChevronButtonComponent {
  isCollapsed = model<boolean>();
  index = input<number>();
}
