import { computed, inject, Injectable, Signal, signal } from '@angular/core';
import { UserService } from '@ikp/service/user/user.service';
import { Permission } from '@ikp/model/permission.model';
import { ROUTE_PERMISSION_CONFIG } from '@ikp/config/route-permissions.config';
import { PathMatcher } from '@shared/utils/path-matcher';
import { of, from, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class UserAccessService {
  private userService = inject(UserService);

  private userPermissions = signal<Permission[]>(null);

  constructor() {
    this.reloadUserPermissions();
  }

  async reloadUserPermissions(): Promise<void> {
    return this.userService.getUserData().then(userdata => {
      if (userdata) {
        this.userPermissions.set(userdata.permissions);
      }
    });
  }

  areUserPermissionsLoaded(): boolean {
    return this.userPermissions() !== null;
  }

  public hasUserAccessTo(path: string): boolean {
    return this.hasUserAccessAsSignal(path)();
  }

  public hasUserAccessAsSignal(path: string): Signal<boolean> {
    return computed(() => {
      if (!path || this.userPermissions() === null) {
        return false;
      }
      const requiredPermissions = Object.entries(ROUTE_PERMISSION_CONFIG).find(([key]) => new PathMatcher(key).matches(path))?.[1];

      if (!requiredPermissions) {
        return true;
      }
      if (this.userPermissions().length > 0) {
        return (
          requiredPermissions.every((permission: Permission) => this.userPermissions().includes(permission)) ||
          this.userPermissions().includes(Permission.ADMIN)
        );
      }
      return requiredPermissions.length === 0;
    });
  }

  public hasUserPermission(permission: Permission): boolean {
    if (this.userPermissions() === null) {
      return false;
    }
    return this.userPermissions().includes(permission) || this.userPermissions().includes(Permission.ADMIN);
  }

  public hasPermissionAsObservable(permission: Permission): Observable<boolean> {
    if (!this.areUserPermissionsLoaded()) {
      return from(this.reloadUserPermissions().then(() => this.hasUserPermission(permission)));
    }
    return of(this.hasUserPermission(permission));
  }
}
