import { Permission } from '@ikp/model/permission.model';

/**
 * This is the configuration for the permissions of the routes.
 * The key is the route path and the value is an array of permissions of which any is required to access the page.
 * The path supports a simplified version of ant paths, with the special characters ** for 'match anything' and * for 'match anything until the next /'.
 * For details regarding the matching see the PathMatcher class.
 * Note: The ADMIN permission contains all other permissions, so the following configuration would allow everyone with
 * either the RECHNUNGEN permission or the ADMIN permission to access the path '/foo':
 * ```
 * export const ROUTE_PERMISSION_CONFIG = {
 *   '/foo': [Permission.RECHNUNGEN],
 * };
 * ```
 */
export const ROUTE_PERMISSION_CONFIG: { [path: string]: Permission[] } = {
  '/home/berechtigungen': [Permission.ADMIN],
  '/home/zaehlpunkte': [Permission.ZAEHLPUNKTE],
  '/home/zaehlpunkte/**': [Permission.ZAEHLPUNKTE],
  '/home/rechnungen': [Permission.RECHNUNGEN],
  '/home/unternehmensdaten': [Permission.UNTERNEHMENSDATEN],
};
