import { IAdresse, transformAdresseToAddress } from '@shared/model/address.model';
import { Company } from '@shared/model/company.model';
import { Page } from '@shared/model/page.model';
import { RegistrationStatus } from '@shared/model/registration.model';

export interface IUnternehmen {
  name: string;
  gpNr: string;
  email: string;
  uid: string;
  adminUserKeycloakId: string;
  hausverwaltung: boolean;
  mitarbeiterAnzahl: string;
  branche: string;
  adresse: IAdresse;
  status: RegistrationStatus;
}

export type IUnternehmenResponse = Page<IUnternehmen>;

export function transformToCompanies(response: IUnternehmen[]): Company[] {
  return response.map((unternehmenRaw: IUnternehmen) => {
    const address = transformAdresseToAddress(unternehmenRaw.adresse);
    return new Company(unternehmenRaw, address);
  });
}
