import { AbstractControl, ValidationErrors, Validators } from '@angular/forms';

export class CustomValidators {
  static readonly CUSTOMER_NUMBER_LENGTH = 10;
  static readonly CUSTOMER_NUMBER_REGEX = '^[0-9]*$';
  static readonly ANY_LETTER_REGEX = /^[\p{Letter}\s\-.']+$/u;
  static readonly PHONE_NUMBER_REGEX = /^\+?[0-9]{10,15}$/;
  static readonly UID_REGEX = /^[A-Z0-9]{8,12}$/;
  static readonly NO_SPECIAL_CHARS_REGEX = /^[a-zA-Z0-9]*$/;
  static readonly FIRMENBUCH_NR_REGEX = /^\d{1,6}[A-Za-z]$/;
  static readonly HOUSE_NUMBER_STAIRCASE_REGEX = /^\d+[a-zA-Z]?(-\d+)?\/?\d*[a-zA-Z]?$/;
  static readonly PLZ_REGEX = /^\d{4}$/;
  static readonly CITY_REGEX = /^[a-zA-ZäöüÄÖÜß\s\-.]+$/;
  static readonly STREET_REGEX = /^[a-zA-ZäöüÄÖÜß\s-]+$/;
  static readonly METER_POINT_NUMBER_REGEX = /^[A-Z0-9]{12,}$/;
  static readonly METER_NUMBER_REGEX = /^[A-Za-z0-9\s]{8,}$/;
  static readonly METER_COUNT_REGEX = /^\s*\d+(,\d*)?\s*$/;

  static firstNameValidator(control: AbstractControl): ValidationErrors | null {
    return CustomValidators.nameValidator(control, 'firstName');
  }

  static lastNameValidator(control: AbstractControl): ValidationErrors | null {
    return CustomValidators.nameValidator(control, 'lastName');
  }

  static companyNameValidator(control: AbstractControl): ValidationErrors | null {
    return CustomValidators.nameValidator(control, 'companyName');
  }

  static nameValidator(control: AbstractControl, fieldName: string): ValidationErrors | null {
    const value = control.value?.trim();
    //
    if (!value) {
      return { translationKey: `inputErrors.blank.${fieldName}` };
    }
    if (value.length < 2) {
      return { translationKey: `inputErrors.tooShort.${fieldName}` };
    }
    if (!CustomValidators.ANY_LETTER_REGEX.test(value)) {
      return { translationKey: `inputErrors.invalid.${fieldName}` };
    }
    return null;
  }

  static emailValidator(control: AbstractControl): ValidationErrors | null {
    const value = control.value?.trim();
    if (!value) {
      return { translationKey: 'inputErrors.blank.email' };
    }
    if (Validators.email(control)) {
      return { translationKey: 'inputErrors.invalid.email' };
    }
    return null;
  }

  static optionalPhoneNumberValidator(control: AbstractControl): ValidationErrors | null {
    const value = control.value?.trim();
    if (!value) {
      return null;
    }
    const valueWithoutWhitespace = CustomValidators.getValueWithoutWhitespace(value);
    if (!CustomValidators.PHONE_NUMBER_REGEX.test(valueWithoutWhitespace)) {
      return { translationKey: 'inputErrors.invalid.phoneNumber' };
    }
    return null;
  }

  static uidValidator(control: AbstractControl): ValidationErrors | null {
    const value = control.value?.trim();
    if (!value) {
      return { translationKey: 'inputErrors.blank.uidNr' };
    }
    const valueWithoutWhitespace = CustomValidators.getValueWithoutWhitespace(value);
    if (!CustomValidators.UID_REGEX.test(valueWithoutWhitespace)) {
      return { translationKey: 'inputErrors.invalid.uidNr' };
    }
    return null;
  }

  static optionalFirmenbuchNrValidator(control: AbstractControl): ValidationErrors | null {
    const value = control.value?.trim();
    if (!value) {
      return null;
    }
    const valueWithoutWhitespace = CustomValidators.getValueWithoutWhitespace(value);
    if (!CustomValidators.NO_SPECIAL_CHARS_REGEX.test(valueWithoutWhitespace)) {
      return { translationKey: 'inputErrors.invalid.firmenbuchNrSpecialChar' };
    }
    if (valueWithoutWhitespace.length > 7) {
      return { translationKey: 'inputErrors.tooLong.firmenbuchNr' };
    }
    if (!CustomValidators.FIRMENBUCH_NR_REGEX.test(valueWithoutWhitespace)) {
      return { translationKey: 'inputErrors.invalid.firmenbuchNr' };
    }
    return null;
  }

  static plzValidator(control: AbstractControl): ValidationErrors | null {
    const value = control.value;
    if (!value) {
      return { translationKey: 'inputErrors.blank.plz' };
    }
    if (!CustomValidators.PLZ_REGEX.test(value)) {
      return { translationKey: 'inputErrors.invalid.plz' };
    }
    return null;
  }

  static cityValidator(control: AbstractControl): ValidationErrors | null {
    const value = control.value?.trim();
    if (!value) {
      return { translationKey: 'inputErrors.blank.city' };
    }
    if (value.length < 2) {
      return { translationKey: 'inputErrors.tooShort.city' };
    }
    if (!CustomValidators.CITY_REGEX.test(value)) {
      return { translationKey: 'inputErrors.invalid.city' };
    }
    return null;
  }

  static streetValidator(control: AbstractControl): ValidationErrors | null {
    const value = control.value?.trim();
    if (!value) {
      return { translationKey: 'inputErrors.blank.street' };
    }
    if (value.length < 2) {
      return { translationKey: 'inputErrors.tooShort.street' };
    }
    if (!CustomValidators.STREET_REGEX.test(value)) {
      return { translationKey: 'inputErrors.invalid.street' };
    }
    return null;
  }

  static houseNumberStaircaseValidator(control: AbstractControl): ValidationErrors | null {
    const value = control.value?.trim();
    if (!value || value?.length === 0) {
      return { translationKey: 'inputErrors.blank.houseNumberStaircase' };
    }
    if (!CustomValidators.HOUSE_NUMBER_STAIRCASE_REGEX.test(value)) {
      return {
        translationKey: 'inputErrors.invalid.houseNumberStaircase',
      };
    }
    return null;
  }

  static doorNumberValidator(control: AbstractControl): ValidationErrors | null {
    const value = control.value?.trim();
    if (!value) {
      return null;
    }
    if (!CustomValidators.NO_SPECIAL_CHARS_REGEX.test(value)) {
      return { translationKey: 'inputErrors.invalid.door' };
    }
    return null;
  }

  static meterPointNumberValidator(control: AbstractControl): ValidationErrors | null {
    const value = control.value;
    if (!value) {
      return { translationKey: 'inputErrors.blank.meterPointNumber' };
    }
    const valueWithoutWhitespace = CustomValidators.getValueWithoutWhitespace(value);
    if (!CustomValidators.METER_POINT_NUMBER_REGEX.test(valueWithoutWhitespace)) {
      return { translationKey: 'inputErrors.invalid.meterPointNumber' };
    }
    return null;
  }

  static meterNumberValidator(control: AbstractControl): ValidationErrors | null {
    const value = control.value;
    if (!value) {
      return { translationKey: 'inputErrors.blank.meterNumber' };
    }
    const valueWithoutWhitespace = CustomValidators.getValueWithoutWhitespace(value);
    if (!CustomValidators.METER_NUMBER_REGEX.test(valueWithoutWhitespace)) {
      return { translationKey: 'inputErrors.invalid.meterNumber' };
    }
    return null;
  }

  static meterCountValidator(control: AbstractControl): ValidationErrors | null {
    const value = control.value;
    if (!value) {
      return { translationKey: 'inputErrors.blank.meterCount' };
    }
    if (!CustomValidators.METER_COUNT_REGEX.test(value)) {
      return { translationKey: 'inputErrors.invalid.meterCount' };
    }
    if (value < 0) {
      return { translationKey: 'inputErrors.invalid.meterCount' };
    }
    return null;
  }

  static readonly CUSTOMER_NUMBER = Validators.compose([
    Validators.minLength(this.CUSTOMER_NUMBER_LENGTH),
    Validators.maxLength(this.CUSTOMER_NUMBER_LENGTH),
    Validators.pattern(CustomValidators.CUSTOMER_NUMBER_REGEX),
  ]);

  private static getValueWithoutWhitespace(value: string): string {
    return value?.replace(/\s/g, '');
  }
}
