import { Component, Input, Output, EventEmitter, input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslatePipe } from '@ngx-translate/core';

@Component({
  selector: 'lib-ikp-info-card-link',
  standalone: true,
  imports: [CommonModule, TranslatePipe],
  templateUrl: './info-card-link.component.html',
})
export class InfoCardLinkComponent {
  @Input() text: string;
  @Input() color: string;
  @Output() readonly actionClicked = new EventEmitter<void>();
  index = input<number>();
}
