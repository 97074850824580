export class PathMatcher {
  private regex: RegExp;

  constructor(pattern: string) {
    this.regex = this.toRegex(pattern);
  }

  matches(value: string): boolean {
    return this.regex.test(value.trim());
  }

  private toRegex(pattern: string) {
    const regexPattern = pattern
      .replaceAll('.', '\\.')
      .replaceAll('?', '\\?')
      .replaceAll('+', '\\+')
      .replace(/[^*]\*[^*]/g, '[^/]*')
      .replace(/\*\*/g, '.*');
    return new RegExp(regexPattern);
  }
}
