<div class="font-size-14 lh-lg">
  <div class="fw-bold">{{ key() | translate }}:</div>
  @if (value()) {
    <div [id]="contentDivId()">
      {{ value() | translate }}
    </div>
  } @else {
    <ng-content></ng-content>
  }
</div>
