<div class="d-flex align-items-center">
  <div class="flex-grow-1 overflow-hidden">
    <div class="d-flex align-items-center">
      @if (isCheckbox) {
        <input
          class="form-check-input action-checkbox"
          type="checkbox"
          (change)="onActionClicked()"
          [checked]="isChecked"
          [attr.data-test]="'info-card-checkbox-' + index()"
        />
      }
      @if (iconPath) {
        <div class="mr-2">
          <img ngSrc="{{ iconPath }}" alt="" width="16" height="20" />
        </div>
      }
      <div class="flex-grow-1 overflow-hidden">
        <h2 class="d-block text-truncate mb-0">{{ title | translate }}</h2>
        @if (subtitle) {
          <div class="subtitle d-flex gap-2 subtitle-text" [innerHtml]="subtitle | translate"></div>
        }
      </div>
    </div>
  </div>
  <div class="action-card">
    @switch (infoCardType) {
      @case (InfoCardType.BADGE) {
        <mwe-availability-badge
          [activeBadge]="true"
          [inactiveBadge]="false"
          [unknownBadge]="false"
          [hoverMessage]="'Aktiv'"
        ></mwe-availability-badge>
      }
      @case (InfoCardType.ACCORDION) {
        <div class="action-chevron">
          <lib-ikp-info-card-chevron-button [(isCollapsed)]="isCollapsed" [index]="index()" />
        </div>
      }
      @case (InfoCardType.LINK) {
        <div class="action-link">
          <lib-ikp-info-card-link
            [text]="actionText"
            [color]="actionColor"
            [index]="index()"
            (actionClicked)="onActionClicked()"
            [attr.data-test]="'info-card-actionlink-idx-' + index()"
          />
        </div>
      }
      @default {}
    }
  </div>
</div>
