import { Component, EventEmitter, input, Input, model, Output } from '@angular/core';
import { CommonModule, NgOptimizedImage } from '@angular/common';
import { InfoCardLinkComponent } from '../info-card-link/info-card-link.component';
import { UiComponentsModule } from '@mwe/ui/components';
import { InfoCardChevronButtonComponent } from '../info-card-chevron-button/info-card-chevron-button.component';
import { InfoCardType } from '@shared/model/info-card-type.enum';

@Component({
  selector: 'lib-ikp-info-card-title',
  standalone: true,
  imports: [CommonModule, NgOptimizedImage, InfoCardLinkComponent, UiComponentsModule, InfoCardChevronButtonComponent],
  templateUrl: './info-card-title.component.html',
  styleUrl: './info-card-title.component.scss',
})
export class InfoCardTitleComponent {
  @Input() infoCardType: InfoCardType;
  @Input() title: string;
  @Input() subtitle: string;
  @Input() iconPath: string;
  @Input() isCheckbox: boolean;
  @Input() isChecked: boolean;
  @Input() actionText: string;
  @Input() actionColor: string;
  @Output() readonly actionClicked = new EventEmitter<void>();
  isCollapsed = model<boolean>();
  index = input<number>();

  onActionClicked() {
    // maybe track action here
    this.actionClicked.emit();
  }

  protected readonly InfoCardType = InfoCardType;
}
