<div class="link">
  <button
    type="button"
    class="btn btn-link btn-link--{{ color }} text-start focus-border-large"
    (click)="actionClicked.emit()"
    [attr.data-test]="'info-card-link' + index()"
  >
    {{ text | translate | uppercase}}
  </button>
</div>
