import { Component, input } from '@angular/core';
import { TranslatePipe } from '@ngx-translate/core';

@Component({
  selector: 'lib-info-card-content-field',
  standalone: true,
  imports: [TranslatePipe],
  templateUrl: './info-card-content-field.component.html',
})
export class InfoCardContentFieldComponent {
  key = input<string>();
  value = input<string>();
  contentDivId = input<string>();
}
