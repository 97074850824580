export enum Permission {
  ADMIN = 'ADMIN',
  ZAEHLPUNKTE = 'ZAEHLPUNKTE',
  RECHNUNGEN = 'RECHNUNGEN',
  UNTERNEHMENSDATEN = 'UNTERNEHMENSDATEN',
}

export function permissionDisplayName(permission: Permission) {
  switch (permission) {
    case Permission.ADMIN:
      return 'Admin';
    case Permission.ZAEHLPUNKTE:
      return 'Zählpunkte';
    case Permission.RECHNUNGEN:
      return 'Rechnungen';
    case Permission.UNTERNEHMENSDATEN:
      return 'Unternehmensdaten';
  }
}

export interface PermissionDetail {
  permission: Permission;
  displayName: string;
  hasPermission: boolean;
}
