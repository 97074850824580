import { inject, Injectable, signal } from '@angular/core';
import { ApiService } from '@ikp/service/api/api.service';
import { IUnternehmen, IUnternehmenResponse, transformToCompanies } from '@shared/model/company-http.model';
import { API_ROUTE_COMPANY, getApiUrl } from '@ikp/app.constants';
import { lastValueFrom } from 'rxjs';
import { Company } from '@shared/model/company.model';
import { IkpPopupService } from '@ikp/service/popup/ikp-popup.service';
import { transformAdresseToAddress } from '@shared/model/address.model';

@Injectable({
  providedIn: 'root',
})
export class CompanyService extends ApiService {
  private popupService = inject(IkpPopupService);

  companies = signal<Array<Company>>([]);
  page = signal<number>(1);
  totalCount = signal<number | null>(null);
  totalPages = signal<number>(1);

  loadCompanyData(page: number, size: number): Promise<Company[]> {
    page -= 1;
    return lastValueFrom(
      this.httpClient.get<IUnternehmenResponse>(getApiUrl(this.apiUrl, API_ROUTE_COMPANY), {
        params: { page: page.toString(), size: size.toString() },
      }),
    )
      .then((response: IUnternehmenResponse) => {
        const companies = transformToCompanies(response.content);
        this.companies.set(companies);
        this.totalCount.set(response.page.totalElements);
        this.page.set(response.page.number + 1);
        this.totalPages.set(response.page.totalPages);
        return companies;
      })
      .catch(() => {
        this.handleError();
        return [];
      });
  }

  loadAllCompanies(): Promise<Company[]> {
    return lastValueFrom(
      this.httpClient.get<IUnternehmenResponse>(getApiUrl(this.apiUrl, API_ROUTE_COMPANY), {
        params: { page: '0', size: '100000' },
      }),
    )
      .then((response: IUnternehmenResponse) => {
        return transformToCompanies(response.content);
      })
      .catch(() => {
        this.handleError();
        return [];
      });
  }

  loadCompanyByGpNr(gpNr: string): Promise<Company> {
    return lastValueFrom(this.httpClient.get<IUnternehmen>(getApiUrl(this.apiUrl, `${API_ROUTE_COMPANY}/${gpNr}`)))
      .then((response: IUnternehmen): Company => {
        const address = transformAdresseToAddress(response.adresse);
        return new Company(response, address);
      })
      .catch(() => {
        this.handleError();
        return null;
      });
  }

  private handleError(): void {
    this.popupService.handleErrorPopup('download-error', 'global.errors.company.title');
  }
}
