import { Component, input } from '@angular/core';
import { NgOptimizedImage } from '@angular/common';
import { TranslatePipe } from '@ngx-translate/core';

@Component({
  selector: 'lib-ikp-helper-text',
  standalone: true,
  imports: [NgOptimizedImage, TranslatePipe],
  template: `
    <div class="d-inline-flex mt-2">
      <img class="mr-1" ngSrc="assets/icon/helper-info-grey-small.svg" alt="icon" width="12" height="12" />
      <div class="font-size-11 ">{{ helperText() | translate }}</div>
    </div>
  `,
})
export class HelperTextComponent {
  helperText = input<string>('');
}
