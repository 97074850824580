import { Address, createAddress } from '@mwe/models';

// for the http requests
export interface IAdresse {
  strasse: string;
  hausnummer: string;
  stiege?: string;
  top?: string;
  adresszusatz?: string;
  adresshinweis?: string;
  plz: string;
  ort: string;
  land: string;
}

export interface IAdresseFilter {
  plz: string;
  ort: string;
  strasse: string;
  hausnummer: string;
  stiege?: string;
  top?: string;
}

export function transformAdresseToAddress(adresse: IAdresse): Address {
  return createAddress({
    id: 1,
    street: adresse.strasse,
    streetNumber: adresse.hausnummer,
    block: adresse.stiege,
    doorNumber: adresse.top,
    hint: adresse.adresszusatz,
    postcode: adresse.plz,
    city: adresse.ort,
    country: adresse.land,
  });
}

export function transformAddressToRequestBody(address: Address): IAdresse {
  return {
    strasse: address.street,
    hausnummer: address.streetNumber,
    stiege: address.block,
    top: address.doorNumber,
    adresszusatz: address.hint,
    plz: address.postcode,
    ort: address.city,
    land: address.country,
  };
}
