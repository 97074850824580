import { IUnternehmen } from '@shared/model/company-http.model';
import { RegistrationStatus } from '@shared/model/registration.model';
import { Address } from '@mwe/models';

export interface ICompany {
  name: string;
  gpNr: string;
  email: string;
  uid: string;
  hausverwaltung: boolean;
  mitarbeiterAnzahl?: string;
  branche?: string;
  address?: Address;
  status: RegistrationStatus;
}

export class Company implements ICompany {
  name: string;
  gpNr: string;
  email: string;
  uid: string;
  adminUserKeycloakId?: string;
  hausverwaltung: boolean;
  mitarbeiterAnzahl?: string;
  branche?: string;
  address?: Address;
  status: RegistrationStatus;

  constructor(unternehmen: IUnternehmen, address: Address) {
    this.name = unternehmen.name;
    this.gpNr = unternehmen.gpNr;
    this.email = unternehmen.email;
    this.uid = unternehmen.uid;
    this.adminUserKeycloakId = unternehmen.adminUserKeycloakId;
    this.hausverwaltung = unternehmen.hausverwaltung;
    this.mitarbeiterAnzahl = unternehmen.mitarbeiterAnzahl;
    this.branche = unternehmen.branche;
    this.address = address;
    this.status = unternehmen.status;
  }
}
